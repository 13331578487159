.subUnsub {
    margin-bottom: 10px;
}

.activePassive {
    margin-bottom: 10px;
    margin-inline: 5px;
}

.alldivs {
    display: flex;
    justify-content: space-around;
    
}