.nameField {
    border: 1px solid gray;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
    min-width: 100px;
    margin-left: 2%;
    margin-top: 1%;
    cursor: default;
}
.emailField {
    border: 1px solid gray;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 15px;
    min-width: 100px;
    margin-left: 2%;
    margin-top: 1%;
    cursor: default;
}
.textField {
    border: 1px solid gray;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
    width: 96%;
    margin-left: 2%;
    margin-top: 1%;
    overflow-wrap: break-word;
    min-height: 150px;
    cursor: default;
}


.backBtn {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #4FA607;
    border-radius: 100%;
    border: 2px solid #468017fe;
}
i {
    color: #fff;
}