.priceBox {
    border-radius: 6px;
    padding: 8px 12px ;
    width: 80px;
    color: #fff;
    display: flex;
    justify-content: center;
}
.monthBox {
    border-radius: 6px;
    padding: 8px 12px ;
    width: 135px;
    color: #fff;
    display: flex;
    justify-content: center;
}
.backBtn {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #4FA607;
    border-radius: 100%;
    border: 2px solid #468017fe;
}
i {
    color: #fff;
}

.inputField {
    border: 1px solid rgba(128, 128, 128, 0.696);
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
    width: 40%;
    margin-left: 2%;
    margin-top: 1%;
    outline: none;
    transition: 0.2s ease-in-out;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.tabDiv {
    width: 95%;
    margin: auto;
}