.input {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    margin: 8px 6px;
    box-sizing: border-box;
    outline: none;
    transition: all 0.3s ease-in-out;
    width: 60%;
}
.isCompany {
    width: 120px;
}
.isCompanyCheck {
    width: 15px;
    margin: 0 10px;
}